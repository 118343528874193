<template>
  <!-- 报表 -->
  <div class="report_wrapper">
    <div
      class="titleBar"
      v-if="isApp"
      :style="{ 'background-color': '#fff' }"
    ></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>收费情况</span>
      </div>
    </div>

    <div class="report_top">
      <div
        class="top_type"
        @click="dateTypeBool = !dateTypeBool"
        v-show="dateTypeBool"
        style="background-color: #67bd64 "
      >
        日报
      </div>
      <div
        class="top_type"
        @click="dateTypeBool = !dateTypeBool"
        v-show="!dateTypeBool"
        style="background-color: #e75e58 "
      >
        月报
      </div>
      <div class="top_date" @click="showCalendar = true">
        <div class="date_start date">
          <div class="date_title">开始时间</div>
          <div class="date_time">
            <span
              v-show="
                new Date(startDate).getFullYear() != nowDate.getFullYear() &&
                  dateTypeBool
              "
              >{{ startDate | MMM_Do_filter("ymd") }}<br
            /></span>
            {{ startDate | MMM_Do_filter(dateTypeBool ? "md" : "ym") }}
          </div>
        </div>
        <div class="date_end date">
          <div class="date_title">结束时间</div>
          <div class="date_time">
            <span
              v-show="
                new Date(startDate).getFullYear() != nowDate.getFullYear() &&
                  dateTypeBool
              "
              >{{ endDate | MMM_Do_filter("ymd") }}<br
            /></span>
            {{ endDate | MMM_Do_filter(dateTypeBool ? "md" : "ym") }}
          </div>
        </div>
      </div>
      <div class="top_btn" @click="requestReportData">
        查询
      </div>
    </div>

    <div class="report_box">
      <div class="report_scroll">
        <div
          class="report_list"
          v-for="(item, index) in reportList"
          :key="index"
        >
          <div class="list_title_box" v-if="item.stationName">
            <div class="list_title">
              <div class="title_text">{{ item.stationName }}</div>
              <div class="title_num">
                <span class="num">{{ item.parkingSpaceQty }}</span> 个车位
              </div>
            </div>
          </div>
          <div class="list_content">
            <div class="content_item" v-if="!item.stationName">
              <div class="item_label">车位总数</div>
              <div class="item_num">{{ item.parkingSpaceQty }}</div>
            </div>
            <div class="content_item">
              <div class="item_label">订单总流水</div>
              <div class="item_num">{{ item.orderQty }}</div>
              <div
                class="item_icon"
                @touchstart.prevent="onTouchStart(1, item, $event)"
                @touchend.prevent="onTouchEnd"
              >
                <div class="icon">?</div>
              </div>
            </div>
            <div class="content_item">
              <div class="item_label">已支付车辆订单流水总数</div>
              <div class="item_num">{{ item.vehicleOrderQty }}</div>
              <div
                class="item_icon"
                @touchstart.prevent="onTouchStart(2, item, $event)"
                @touchend.prevent="onTouchEnd"
              >
                <div class="icon">?</div>
              </div>
            </div>
            <div class="content_item">
              <div class="item_label">订单流水总金额</div>
              <div class="item_num">{{ item.orderAmount }}</div>
            </div>
            <div class="content_item">
              <div class="item_label">减免流水总金额</div>
              <div class="item_num">{{ item.reduceAmount }}</div>
            </div>
            <div class="content_item">
              <div class="item_label">应收流水总金额</div>
              <div class="item_num">{{ item.payableAmount }}</div>
            </div>
            <div class="content_item">
              <div class="item_label">欠费流水总金额</div>
              <div class="item_num">{{ item.arrearsAmount }}</div>
            </div>
            <div class="content_item">
              <div class="item_label">实收流水总金额</div>
              <div class="item_num">{{ item.amount }}</div>
              <div
                class="item_icon"
                @touchstart.prevent="onTouchStart(3, item, $event)"
                @touchend.prevent="onTouchEnd"
              >
                <div class="icon">?</div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="report_table"
          :style="{ top: `${tableTop}px` }"
          v-show="showTable"
        >
          <ul v-if="reportTableType == 1">
            <li>
              <div>已支付</div>
              <div>已欠费</div>
              <div>已退款</div>
              <div>已补缴</div>
              <div>合计</div>
            </li>
            <li>
              <div>{{ reportTableData.normalPaidOrderQty }}</div>
              <div>{{ reportTableData.arrearsOrderQty }}</div>
              <div>{{ reportTableData.refundOrderQty }}</div>
              <div>{{ reportTableData.recoverOrderQty }}</div>
              <div>{{ reportTableData.orderQty }}</div>
            </li>
          </ul>

          <ul v-if="reportTableType == 2">
            <li>
              <div>普通车</div>
              <div>月保车</div>
              <div>白名单</div>
              <div>免费车</div>
              <div>零费用</div>
              <div>合计</div>
            </li>
            <li>
              <div>{{ reportTableData.normalOrderQty }}</div>
              <div>{{ reportTableData.prepayOrderQty }}</div>
              <div>{{ reportTableData.whiteOrderQty }}</div>
              <div>{{ reportTableData.freeOrderQty }}</div>
              <div>{{ reportTableData.zeroCostOrderQty }}</div>
              <div>{{ reportTableData.vehicleOrderQty }}</div>
            </li>
          </ul>

          <ul v-if="reportTableType == 3">
            <li>
              <div>线上支付</div>
              <div>线下支付</div>
              <div>退款</div>
              <div>合计</div>
            </li>
            <li>
              <div>{{ reportTableData.onlinePayAmount }}</div>
              <div>{{ reportTableData.offlinePayAmount }}</div>
              <div>{{ reportTableData.refundAmount }}</div>
              <div>{{ reportTableData.amount }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <van-calendar
      v-model="showCalendar"
      type="range"
      @confirm="onConfirm"
      confirm-disabled-text="请选择日期"
      :show-title="false"
      :allow-same-day="true"
      color="#4ea2eb"
      :min-date="new Date('2000/1/1')"
      :max-date="nowDate"
    />
  </div>
</template>

<script>
let nowDate = new Date();
import { mapState } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    showCalendar: false, //日历弹出层
    startDate: moment().format("yyyy-MM-DD"), //开始时间
    endDate: moment().format("yyyy-MM-DD"), //结束时间

    dateTypeBool: true, //true--日收费报表  false--月收费报表
    reportType: 1, //1--总报表 2--详细报表
    tableTop: 0, //当前元素距离顶部距离
    showTable: false, //详细内容弹出层
    reportList: [], //详细内容列表
    reportTableType: 1, //详细内容类型
    reportTableData: {}, //详细内容数据
    nowDate
  }),
  methods: {
    /**
     * 日历选择器确认函数
     */
    onConfirm(date) {
      const [start, end] = date;
      this.showCalendar = false;
      this.startDate = moment(start).format("yyyy-MM-DD");
      this.endDate = moment(end).format("yyyy-MM-DD");
    },

    /**
     * 开始触摸
     *  type：详细内容类型
     *  item：详细内容数据
     */
    onTouchStart(type, item, $event) {
      //   console.log($event.touches[0].pageY , $event.touches[0].radiusY);\
      this.reportTableType = type;
      if (type == 1) {
        this.reportTableData = {
          normalPaidOrderQty: item.normalPaidOrderQty,
          refundOrderQty: item.refundOrderQty,
          recoverOrderQty: item.recoverOrderQty,
          arrearsOrderQty: item.arrearsOrderQty,
          orderQty: item.orderQty
        };
      } else if (type == 2) {
        this.reportTableData = {
          normalOrderQty: item.normalOrderQty,
          freeOrderQty: item.freeOrderQty,
          whiteOrderQty: item.whiteOrderQty,
          prepayOrderQty: item.prepayOrderQty,
          zeroCostOrderQty: item.zeroCostOrderQty,
          vehicleOrderQty: item.vehicleOrderQty
        };
      } else {
        this.reportTableData = {
          onlinePayAmount: item.onlinePayAmount,
          offlinePayAmount: item.offlinePayAmount,
          refundAmount: item.refundAmount,
          amount: item.amount
        };
      }

      let pageY = $event.touches[0].pageY;
      this.tableTop = pageY < 150 ? 30 : pageY + 30;
      if (pageY < 150) {
        this.tableTop = 20;
      } else if (pageY + 100 > document.body.clientHeight) {
        this.tableTop = pageY - 80;
      } else {
        this.tableTop = pageY + 30;
      }
      this.showTable = true;
    },

    onTouchEnd() {
      this.showTable = false;
    },

    /**
     * 请求报表数据
     */
    requestReportData() {
      this.$tools.showLoading();
      //dateType:  1--日收费报表  2--月收费报表
      let dateType = this.dateTypeBool ? 1 : 2;
      // this.$get(this.$config.TL_CHARGE_STATUS_GET_STATION_ORDER_FLOW, {
      //   timeFrom: this.startDate,
      //   timeTo: this.endDate,
      //   dateType,
      //   type: this.reportType
      // })
      this.$api
        .getStationOrderFlow({
          timeFrom: this.startDate,
          timeTo: this.endDate,
          dateType,
          type: this.reportType
        })
        .then(res => {
          this.reportList = res["data"]["data"];
          this.$tools.closeLoading();
        })
        .catch(() => {
          this.$tools.closeLoading();
        });
    }
  },

  computed: {
    ...mapState({
      isApp: state => state.isApp
    }),

    reportTableTotal() {
      let total = 0;
      for (let k in this.reportTableData) {
        total += Number(this.reportTableData[k]);
      }
      return total;
    }
  },

  created() {
    //设置moment时区
    moment.locale("zh-cn");
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.reportType = vm.$route.query.reportType;
      vm.requestReportData();
    });
  }
};
</script>

<style lang="less" scoped>
.report_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eceff6;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.report_top {
  // height : 50px ;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  background-color: #fff;

  .top_type {
    width: 50px;
    height: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #67bd64;
    font-size: 16px;
    border-radius: 50%;
  }

  .top_date {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .date {
      padding: 4px 12px;
      text-align: center;
      flex: 1;
      font-size: 14px;
      color: #333;
    }
  }

  .top_btn {
    font-size: 16px;
    padding: 8px 18px;
    background-color: #65a0e6;
    color: #fff;
    border-radius: 6px;
  }
}

.report_box {
  width: 100%;
  flex: 1;
  overflow: hidden;
  padding: 8px 8px 0;

  .report_scroll {
    width: 100%;
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .report_list {
    background-color: #fff;
    border-radius: 6px;
    width: 100%;
    margin-bottom: 8px;

    .list_title_box {
      width: 100%;
      padding-right: 10px;
    }

    .list_title {
      height: 54px;
      width: 100%;
      padding: 0 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(7, 17, 27, 0.1);

      .title_text {
        font-size: 18px;
        color: #333;
        font-weight: 600;
      }

      .title_num {
        font-size: 14px;
        display: flex;
        align-items: center;

        .num {
          font-size: 24px;
          color: #6dc066;
          margin: 0 2px;
        }
      }
    }

    .list_content {
      width: 100%;
      padding: 0 24px 0 14px;
    }
  }
}

.content_item {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(7, 17, 27, 0.1);
  padding: 0 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;

  &:last-child {
    border: 0;
  }

  .item_label {
    flex: 1;
  }

  .item_num {
    flex: 0 0 60px;
  }

  .item_icon {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: orange;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.report_table {
  position: fixed;
  top: 0;
  left: 10px;
  width: calc(100% - 20px);
  height: 70px;
  padding: 6px;
  background-color: #fff;
  box-shadow: 2px 2px 5px #888888;
  border-radius: 4px;

  > ul {
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    //   justify-content: space-between;

    > li {
      flex: 1;
      display: flex;
      justify-content: space-between;

      > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
